import { Directive, Input, OnInit, Self, TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaObserverService } from '@app/core/media-observer/media-observer.service';
import { DestroyService } from '@app/shared/utils/destroy.service';
import { map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appMediaShowDown]',
  providers: [DestroyService],
  standalone: false,
})
export class MediaShowDownDirective implements OnInit {
  @Input('appMediaShowDown') breakpoint!: string;

  @Input('appMediaShowDownIncludeEquals') includeEquals = true;

  protected hasView = false;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly mediaObserver: MediaObserverService,
    @Self() private readonly destroy$: DestroyService,
  ) {}

  public ngOnInit(): void {
    this.mediaObserver.breakpoint$
      .pipe(
        map((breakpoint) => this.mediaObserver.isBreakpointDown(breakpoint, this.breakpoint, this.includeEquals)),
        takeUntil(this.destroy$),
      )
      .subscribe((shouldShow) => {
        if (shouldShow && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!shouldShow && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }
}
