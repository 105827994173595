import { Models } from '@store/casl';

import { SidenavItem } from './sidenav-item.model';

export const SIDENAV_MENU_ITEMS: SidenavItem[] = [
  new SidenavItem({
    svgIcon: 'home',
    name: 'Главная',
    route: '/',
    routerLinkActiveOptions: { exact: true },
    position: 0,
    subject: ['all'],
  }),
  new SidenavItem({
    svgIcon: 'person',
    name: 'Клиенты',
    route: '',
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        name: 'Список клиентов',
        route: '/customers',
        subject: [Models.Customers],
        position: 0,
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Владения',
        route: '/ownerships',
        subject: [Models.Ownerships],
        position: 1,
      }),
    ],
    subject: [Models.Customers, Models.Ownerships],
    position: 1,
  }),
  new SidenavItem({
    svgIcon: 'car',
    name: 'Автомобили',
    route: '',
    position: 2,
    subject: [Models.Vehicles, Models.Devices],
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        name: 'Список машин',
        route: '/vehicles',
        position: 0,
        subject: [Models.Vehicles],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Блоки телематики',
        route: '/telematics-blocks',
        position: 1,
        subject: [Models.Devices],
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'filter',
    name: 'Конфигуратор',
    route: '/configurator',
    position: 3,
    subject: [Models.Configurations, Models.Brands, Models.Colors, Models.CatalogRecords],
  }),
  new SidenavItem({
    svgIcon: 'i-documents-accepted',
    name: 'Заявки',
    route: '',
    position: 4,
    subject: [Models.Tickets, Models.DeviceRepairs],
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        name: 'Установка оборудования',
        route: '/ticket-equipment',
        position: 0,
        subject: [Models.Tickets],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Добавление автомобилей',
        route: '/ticket-car',
        position: 1,
        subject: [Models.Tickets],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Удаление ПД',
        route: '/ticket-delete-personal-data',
        position: 2,
        subject: [Models.Tickets],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Ремонт ЭРА ГЛОНАСС',
        route: '/ticket-repair-era-glonass',
        position: 3,
        subject: [Models.DeviceRepairs],
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'car-repair',
    name: 'Обслуживание авто',
    route: '',
    position: 5,
    subject: ['all'],
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        name: 'Запись на сервис',
        route: '/maintenances',
        position: 0,
        subject: ['all'],
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'product-subscriptions',
    name: 'Подписка',
    route: '',
    position: 6,
    subject: [Models.Subscriptions, Models.Payments, Models.Invoices, Models.Tariffs, Models.Fines, Models.Ckads],
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        name: 'Подписки',
        route: '/subscriptions',
        position: 0,
        subject: [Models.Subscriptions],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Бесплатные периоды',
        route: '/gifted-subscriptions',
        position: 1,
        subject: [Models.Subscriptions],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Платежи',
        route: '/payments',
        position: 2,
        subject: [Models.Payments],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Платежи Mobikey',
        route: '/payments-mobikey',
        position: 3,
        subject: [Models.Payments],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Платежи Штрафы ГИБДД',
        route: '/fines',
        position: 4,
        subject: [Models.Fines],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Платежи Платные дороги',
        route: '/ckads',
        position: 5,
        subject: [Models.Ckads],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Тарифы',
        route: '/tariffs',
        position: 6,
        subject: [Models.Tariffs],
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'devices',
    name: 'Устройства',
    route: '',
    position: 7,
    subject: [Models.DeviceInfo, Models.Firmware, Models.DeviceTests, Models.AvtotorDeviceTests],
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        name: 'Проверка устройств',
        route: '/telematics-blocks-check',
        position: 0,
        subject: [Models.DeviceInfo],
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Прошивка CAN',
        route: '/can-firmware',
        subject: [Models.Firmware],
        position: 1,
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Установки завод AГР',
        route: '/installation-agr',
        subject: [Models.DeviceTests],
        position: 2,
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Установки завод AGK',
        route: '/installation-agk',
        subject: [Models.AgkDeviceTests],
        position: 3,
      }),
      new SidenavItem({
        svgIcon: 'point',
        name: 'Установки завод Автотор',
        route: '/dms-avtotor',
        subject: [Models.AvtotorDeviceTests],
        position: 4,
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'file-excel',
    name: 'Отчеты',
    subject: [Models.Reports],
    route: '',
    position: 8,
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        name: 'Тех. поддержка блоков АГР',
        route: '/reports-agr',
        position: 0,
        subject: [Models.Reports],
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'partners',
    name: 'Партнёры',
    subject: [Models.Partners],
    route: '/partners',
    position: 9,
  }),
  new SidenavItem({
    svgIcon: 'star',
    name: 'Медиаконтент',
    subject: [Models.Stories],
    route: '',
    position: 10,
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        subject: [Models.Stories],
        name: 'Список историй',
        route: '/stories',
        position: 0,
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'apps',
    subject: [Models.Facilities],
    name: 'Услуги',
    route: '/facilities',
    position: 11,
  }),
  new SidenavItem({
    svgIcon: 'supplier',
    name: 'Поставщики',
    subject: [Models.Providers],
    route: '/providers',
    position: 12,
  }),
  new SidenavItem({
    svgIcon: 'ring-medium',
    name: 'Уведомления',
    subject: [Models.Notifications],
    route: '',
    position: 13,
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        subject: [Models.Notifications],
        name: 'История',
        route: '/notifications-history',
        position: 0,
      }),
      new SidenavItem({
        svgIcon: 'point',
        subject: [Models.NotificationTemplates],
        name: 'Шаблоны',
        route: '/notifications-templates',
        position: 1,
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'devices',
    name: 'Дилерские центры',
    route: '',
    position: 14,
    subject: [Models.UploadsHistory],
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        subject: [Models.UploadsHistory],
        name: 'Загрузка данных от ДЦ',
        route: '/dealers-parse',
        position: 0,
        routerLinkActiveOptions: { exact: true },
      }),
      new SidenavItem({
        svgIcon: 'point',
        subject: [Models.UploadsHistory],
        name: 'История загрузок',
        route: '/dealers-parse/history',
        position: 1,
      }),
    ],
  }),
  new SidenavItem({
    svgIcon: 'settings',
    name: 'Система',
    subject: [Models.Users, Models.CronConfigs],
    route: '',
    position: 15,
    subItems: [
      new SidenavItem({
        svgIcon: 'point',
        subject: [Models.Users],
        name: 'Пользователи',
        route: '/users',
        position: 0,
      }),
      new SidenavItem({
        svgIcon: 'point',
        subject: [Models.CronConfigs],
        name: 'Управление кронами',
        route: '/crons',
        position: 0,
      }),
      // new SidenavItem({
      //   svgIcon: 'point',
      //   name: 'Роли',
      //   route: '/temp',
      //   subject: ['all'],
      //   disabled: true,
      //   position: 1,
      // }),
      // new SidenavItem({
      //   svgIcon: 'point',
      //   name: 'Настройки',
      //   subject: ['all'],
      //   route: '/temp',
      //   disabled: true,
      //   position: 2,
      // }),
    ],
  }),
];
